import { createRouter, createWebHistory } from 'vue-router'
import { useGlobalStore } from '@/stores/global'
import { useLoadingStore } from '@/stores/loading'
import { useJwtStore } from '@/stores/jwt'
import { useKcAuthStore } from '@/stores/kcAuth'
import { useCategoriesStore } from '@/stores/categories'
import { useSearchStore } from '@/stores/search'
import { useServiceTemplatesStore } from '@/stores/serviceTemplates'
import { useSettingsStore } from '@/stores/settings'
import { useQueryParams } from '@/router/useQueryParams'
import find from 'lodash/find'
import { useSlugHelper } from '@/router/useSlugHelper'
import { useStatisticsStore } from '@/stores/statistics'

const SLUG_PAGES = {
  PAGE_TERMS: {
    fr: 'termes-et-conditions',
    en: 'terms'
  },
  PAGE_PRIVACY: {
    fr: 'confidentialite',
    en: 'privacy'
  },
  PAGE_COOKY_POLICY: {
    fr: 'politique-temoins-connexion',
    en: 'cookie-policy'
  },
  PAGE_CANCEL_APPT: {
    fr: 'annuler-un-rendez-vous',
    en: 'cancel-appt'
  },
  PAGE_FLU: {
    fr: 'campagne-vaccination-grippe',
    en: 'flu-vaccine-campaign'
  },
  PAGE_FAQ: {
    fr: 'questions-reponses',
    en: 'answers-questions'
  },
  PAGE_CONTACT_US: {
    fr: 'contactez-nous',
    en: 'contact-us'
  }
}

const routerOptions = {
  routes: [
    {
      path: '/:pathMatch(.*)*',
      redirect: 'not-found'
    },
    {
      path: '/',
      name: 'portal',
      alias: '/',
      meta: { title: { fr: 'Accueil', en: 'Home' } },
      component: () => import('@/pages/Portal.vue'),
      children: [
        {
          path: '/not-found',
          name: 'not-found',
          component: () => import('@/pages/NotFound.vue')
        },
        {
          path: '/',
          name: 'home',
          alias: '/accueil',
          meta: { title: { fr: 'Accueil', en: 'Home' } },
          component: () => import('@/pages/home/Home.vue'),
          beforeEnter: async (to, from, next) => {
            await preloadSettingPage(to)
            try {
              const jwtStore = useJwtStore()
              await jwtStore.fetchAccessToken()
              await jwtStore.getLoggedUser()
              await jwtStore.getPatients()
            } catch (error) {
              console.log(error)
              // Silent refresh token error
            }

            useSearchStore().resetSearchCalled()

            next()
          }
        },
        {
          path: '/en/:catchAll(.*)?',
          name: 'english',
          beforeEnter: async (to, from, next) => {
            const globalStore = useGlobalStore()
            await globalStore.setLocale('en')

            if (to.params.catchAll) {
              next({ path: to.params.catchAll, query: to.query })
            } else {
              next({ name: 'home', query: to.query })
            }
          }
        },
        {
          path: '/fr/:catchAll(.*)?',
          name: 'francais',
          beforeEnter: async (to, from, next) => {
            const globalStore = useGlobalStore()
            await globalStore.setLocale('fr')

            if (to.params.catchAll) {
              next({ path: to.params.catchAll, query: to.query })
            } else {
              next({ name: 'home', query: to.query })
            }
          }
        },
        {
          path: `/${SLUG_PAGES.PAGE_TERMS.fr}`,
          alias: `/${SLUG_PAGES.PAGE_TERMS.en}`,
          name: SLUG_PAGES.PAGE_TERMS.fr,
          meta: {
            title: { fr: 'Termes et condititions', en: 'Terms and Conditions' },
            description: {
              fr: 'Consultez cette page pour prendre connaissance de nos termes et conditions.',
              en: 'See this page for our terms and conditions.'
            }
          },
          component: () => import('@/pages/Terms.vue'),
          beforeEnter: async (to, from, next) => {
            const loadingStore = useLoadingStore()
            loadingStore.toggleLoading({ set: true })
            next(await useSlugHelper().checkPageSlug(to.path, SLUG_PAGES.PAGE_TERMS))
          }
        },
        {
          path: `/${SLUG_PAGES.PAGE_PRIVACY.fr}`,
          alias: `/${SLUG_PAGES.PAGE_PRIVACY.en}`,
          name: SLUG_PAGES.PAGE_PRIVACY.fr,
          meta: {
            title: { fr: 'Confidentialité', en: 'Privacy' },
            description: {
              fr: 'Consultez cette page pour prendre connaissance de notre politique de confidentialité.',
              en: 'Visit this page to read our privacy policy.'
            }
          },
          component: () => import('@/pages/Privacy.vue'),
          beforeEnter: async (to, from, next) => {
            const loadingStore = useLoadingStore()
            loadingStore.toggleLoading({ set: true })
            next(await useSlugHelper().checkPageSlug(to.path, SLUG_PAGES.PAGE_PRIVACY))
          }
        },
        {
          path: `/${SLUG_PAGES.PAGE_COOKY_POLICY.fr}`,
          alias: `/${SLUG_PAGES.PAGE_COOKY_POLICY.en}`,
          name: SLUG_PAGES.PAGE_COOKY_POLICY.fr,
          meta: {
            title: { fr: 'Politique relative aux témoins de connexion', en: 'Cookie Policy' },
            description: {
              fr: 'Consultez cette page pour prendre connaissance de notre politique relative aux témoins de connexion.',
              en: 'Visit this page to read our cookie policy.'
            }
          },
          component: () => import('@/pages/CookiePolicy.vue'),
          beforeEnter: async (to, from, next) => {
            const loadingStore = useLoadingStore()
            loadingStore.toggleLoading({ set: true })
            next(await useSlugHelper().checkPageSlug(to.path, SLUG_PAGES.PAGE_COOKY_POLICY))
          }
        },
        {
          path: `/${SLUG_PAGES.PAGE_CANCEL_APPT.fr}`,
          alias: `/${SLUG_PAGES.PAGE_CANCEL_APPT.en}`,
          name: SLUG_PAGES.PAGE_CANCEL_APPT.fr,
          meta: {
            title: { fr: 'Annuler un rendez-vous', en: 'Cancel an Appointment' },
            description: {
              fr: "Consultez cette page pour connaître les différentes façons d'annuler un rendez-vous.",
              en: 'Consult this page for information on how to cancel an appointment.'
            }
          },
          component: () => import('@/pages/cancelAppointment/CancelAppointment.vue'),
          beforeEnter: async (to, from, next) => {
            const loadingStore = useLoadingStore()
            loadingStore.toggleLoading({ set: true })
            next(await useSlugHelper().checkPageSlug(to.path, SLUG_PAGES.PAGE_CANCEL_APPT))
          }
        },
        {
          path: `/${SLUG_PAGES.PAGE_FLU.fr}`,
          alias: `/${SLUG_PAGES.PAGE_FLU.en}`,
          name: SLUG_PAGES.PAGE_FLU.fr,
          meta: { title: { fr: 'Campagne de vaccination grippe', en: 'Flu Vaccine Campaign' } },
          redirect: '/'
          // component: () => import('../pages/FluCampaign.vue'),
          // beforeEnter: (to, from, next) => {
          //     if (Store.getters['getStyle'] !== 'clicsante') {
          //         next('/not-found');
          //         return;
          //     }
          //     Store.dispatch('loading/toggleLoading', { set: true })
          //         .then(() => {
          //             next(await useSlugHelper().checkPageSlug(to.path, next, SLUG_PAGES.PAGE_FLU));
          //         });
          // }
        },
        {
          path: `/${SLUG_PAGES.PAGE_FAQ.fr}`,
          alias: `/${SLUG_PAGES.PAGE_FAQ.en}`,
          name: SLUG_PAGES.PAGE_FAQ.fr,
          props: (route) => ({ query: route.query }),
          meta: {
            title: { fr: 'Questions et Reponses', en: 'Answers & Questions' },
            description: {
              fr: 'Consultez cette page pour prendre connaissance des différentes questions les plus fréquemment posées concernant Clic Santé.',
              en: 'Consult this page for the most frequently asked questions about Clic Santé.'
            }
          },
          component: () => import('@/pages/QuestionsReponses/QuestionsReponses.vue'),
          beforeEnter: async (to, from, next) => {
            const loadingStore = useLoadingStore()
            loadingStore.toggleLoading({ set: true })
            next(await useSlugHelper().checkPageSlug(to.path, SLUG_PAGES.PAGE_FAQ))
          }
        },
        {
          path: `/${SLUG_PAGES.PAGE_CONTACT_US.fr}`,
          alias: `/${SLUG_PAGES.PAGE_CONTACT_US.en}`,
          name: SLUG_PAGES.PAGE_CONTACT_US.fr,
          meta: {
            title: { fr: 'Contactez Nous', en: 'Contact Us' },
            description: {
              fr: 'Cette page vous fournit les coordonnées nécessaires pour nous contacter, que vous soyez un citoyen ou un établissement de santé.',
              en: 'This page provides the contact details you need to reach us, whether you are a citizen or a healthcare facility.'
            }
          },
          component: () => import('@/pages/contactUs/ContactUs.vue'),
          beforeEnter: async (to, from, next) => {
            const loadingStore = useLoadingStore()
            loadingStore.toggleLoading({ set: true })
            next(await useSlugHelper().checkPageSlug(to.path, SLUG_PAGES.PAGE_CONTACT_US))
          }
        },
        {
          path: '/services',
          alias: '/services',
          name: 'services',
          meta: { title: { fr: 'Registre des services', en: 'Service registry' } },
          component: () => import('@/pages/services/Services.vue'),
          beforeEnter: async (to, from, next) => {
            const loadingStore = useLoadingStore()
            const serviceTemplatesStore = useServiceTemplatesStore()
            const categoryStore = useCategoriesStore()

            loadingStore.toggleLoading({ set: true })
            await useSettingsStore().fetchGlobalSettings()
            await serviceTemplatesStore.fetchServiceTemplatesSEO()
            if (categoryStore.allCategories.length === 0) {
              await categoryStore.fetchCategories()
            }

            if (to.query.category) {
              categoryStore.currentCategory = find(categoryStore.allCategories, (category) => {
                return category.id === Number(to.query.category)
              })
            }

            loadingStore.toggleLoading({ set: false })

            next()
          }
        },
        {
          path: '/services/:slug',
          alias: '/services/:slug',
          component: () => import('@/pages/services/service-details/ServiceDetails.vue'),
          beforeEnter: async (to, from, next) => {
            const queryParams = useQueryParams(to)
            const searchStory = useSearchStore()
            searchStory.resetSearchCalled()
            queryParams.extractSearchParams()

            const loadingStore = useLoadingStore()
            loadingStore.toggleLoading({ set: true })

            const serviceTemplatesStore = useServiceTemplatesStore()
            serviceTemplatesStore.currentVisitedServiceTemplate = null
            serviceTemplatesStore.currentServiceTemplate = null

            if (!serviceTemplatesStore.currentVisitedServiceTemplate?.id) {
              await serviceTemplatesStore.fetchServiceTemplateWithSlug(to.params.slug)
            }

            loadingStore.toggleLoading({ set: false })

            next(
              await useSlugHelper().checkServiceSlug(to.params.slug, {
                fr: serviceTemplatesStore.currentVisitedServiceTemplate.slugFr,
                en: serviceTemplatesStore.currentVisitedServiceTemplate.slugEn
              })
            )
          }
        },
        {
          path: '/news/:article',
          alias: '/news/:article',
          component: () => import('@/pages/News.vue'),
          beforeEnter: async (to, from, next) => {
            next()
          }
        },
        {
          path: '/iframe',
          alias: '/iframe',
          component: () => import('@/pages/iframe/result/Results.vue'),
          beforeEnter: async (to, from, next) => {
            await preloadSettingPage(to)
            next()
          }
        }
      ]
    },
    {
      path: '/profile:afterProfile(.*)',
      name: 'profile',
      component: () => import('@/pages/Profile.vue'),
      beforeEnter: async (to, from, next) => {
        if (!useKcAuthStore().isUserLogged) {
          await useKcAuthStore().login(to.path, to.query)
        }
        const loadingStore = useLoadingStore()
        loadingStore.toggleLoading({ set: true })
        next()
      }
    }
  ],
  scrollBehavior(to) {
    if (to.name === 'services') return { top: 0 }
  }
}

const preloadSettingPage = async (to) => {
  const loadingStore = useLoadingStore()
  await loadingStore.toggleLoading({ set: true })

  await useSettingsStore().fetchGlobalSettings()
  await useServiceTemplatesStore().fetchServiceTemplatesSEO()
  useQueryParams(to).extractSearchParams()
}
const initializeRouter = () => {
  routerOptions.history = createWebHistory(import.meta.env.BASE_URL)
  const router = createRouter(routerOptions)
  router.beforeEach(async (to, from, next) => {
    const globalStore = useGlobalStore()
    globalStore.routerHistory = { previous: from, current: to }
    try {
      await useStatisticsStore().fetchStatistics()
    } catch (e) {
      console.error(e)
    }
    next()
  })

  router.afterEach(() => {
    const loadingStore = useLoadingStore()

    if (loadingStore.isLoading) {
      loadingStore.toggleLoading({ set: false })
    }
  })

  return router
}

export { initializeRouter }
