import { defineStore } from 'pinia'

export const useGeolocationStore = defineStore('geolocation', {
  state: () => ({
    postalCode: '' as string | null,
    latitude: null as string | null,
    longitude: null as string | null,
    postalCodeUserInput: null as string | null
  }),
  actions: {
    isGeolocationValid() {
      return (
        (this.postalCode !== null && this.postalCode !== '') ||
        (this.latitude !== null && this.longitude !== null)
      )
    },
    clear() {
      this.postalCode = ''
      this.latitude = null
      this.longitude = null
      this.postalCodeUserInput = null
    }
  }
})
