import { defineStore } from 'pinia'
import { useIframeStore } from '@/stores/iframe'

export const useSearchFiltersStore = defineStore('searchFilters', {
  state: () => ({
    isOpen: true as boolean,
    filtersToApply: [] as any[],
    withoutFeesFilter: true as boolean,
    withFeesFilter: useIframeStore().isIframe,
    withoutAvailsFilter: false as boolean,
    withTa7AvailsFilter: false as boolean,
    withT07AvailsFilter: false as boolean,
    withPharmacy: false as boolean,
    withPrivateClinic: false as boolean,
    withHealthEstablishment: false as boolean,
    mobile: {
      withoutFeesFilter: true as boolean,
      withFeesFilter: useIframeStore().isIframe,
      withoutAvailsFilter: false as boolean,
      withTa7AvailsFilter: false as boolean,
      withT07AvailsFilter: false as boolean,
      withPharmacy: false as boolean,
      withPrivateClinic: false as boolean,
      withHealthEstablishment: false as boolean
    }
  })
})
