import { defineStore } from 'pinia'
import { api } from '@/plugins/api'
import { StatEnum } from '@/enums'

export const useStatisticsStore = defineStore('statistic', {
  state: () => ({
    [StatEnum.APPOINTMENTS_LAST_7_DAYS]: getNumberToCad('70122'),
    [StatEnum.PERCENTAGE_APPOINTMENTS_LAST_7_DAYS_WITHOUT_FEES]: getNumberToCad('90.5'),
    fetched: false
  }),
  actions: {
    async fetchStatistics() {
      if (this.fetched) return
      const response = await api.statistics.getStatistics()
      this.fetched = true

      this[StatEnum.APPOINTMENTS_LAST_7_DAYS] = getNumberToCad(
        response.data[StatEnum.APPOINTMENTS_LAST_7_DAYS]
      )
      this[StatEnum.PERCENTAGE_APPOINTMENTS_LAST_7_DAYS_WITHOUT_FEES] = getNumberToCad(
        response.data[StatEnum.PERCENTAGE_APPOINTMENTS_LAST_7_DAYS_WITHOUT_FEES]
      )
    }
  }
})

function getNumberToCad(number) {
  return new Intl.NumberFormat('fr-FR').format(number)
}
