import axios from 'axios'
import geocoder from './geocoder'
import auth from '@/wrapper/auth'
import avails from '@/wrapper/avails'
import headOffices from '@/wrapper/headoffices'
import settings from '@/wrapper/settings'
import status from '@/wrapper/status'
import favorites from '@/wrapper/favorites'
import serviceTemplates from './serviceTemplates'
import serviceUnified from '@/wrapper/serviceUnified'
import serviceUnifiedWithTags from '@/wrapper/serviceUnifiedWithTags'
import placeServices from '@/wrapper/placeServices'
import appointment from '@/wrapper/appointment'
import category from '@/wrapper/category'
import JwtInterceptor from '@/wrapper/interceptors/jwt'
import statistics from '@/wrapper/statistics'
import { keycloakInterceptorInstance, isUsingKc } from '@/wrapper/interceptors/keycloak'
import { convertApiErrorIntoTrimozError } from '@/utils'
import { useJwtStore } from '@/stores/jwt'
import { TrimozError } from '@/trimoz-vue-error-handling/trimoz-error'

const errorCallbacks = {
  onForbiddenError: (error) => {
    console.log('forbidden error', error)
  },
  onUnauthorizedError: (error) => {
    console.log('unauthorized error', error)
  }
}

export default function (urlV3: string, mainProduct: string) {
  const handleError = function (request) {
    if (!request) return
    return request
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        if (error.code === 'ECONNABORTED') {
          throw new TrimozError(error, 'errors.request.timeout')
        } else if (error.response) {
          if (error.response.status === 403) {
            errorCallbacks.onForbiddenError(error)
          } else if (error.response.status === 401) {
            errorCallbacks.onUnauthorizedError(error)
          }

          throw convertApiErrorIntoTrimozError(error)
        } else {
          throw error
        }
      })
  }

  const APIV3 = {
    url: urlV3,
    http: axios.create({
      baseURL: urlV3,
      headers: {
        PRODUCT: mainProduct,
        'X-TRIMOZ-ROLE': 'public'
      },
      _retry: false,
      withCredentials: true,
      validateStatus: function (status) {
        return status >= 200 && status < 300
      } // Status qui ne throw pas d'error
    }),

    handleError: handleError
  }

  const API_STATIC = {
    url: 'https://static.trimoz.com/clicsante/statistiques/',
    http: axios.create({
      baseURL: 'https://static.trimoz.com/clicsante/statistiques/'
    }),
    handleError: handleError
  }

  if (isUsingKc) {
    APIV3.http?.interceptors.request.use(
      keycloakInterceptorInstance.requestOnFulfilled,
      keycloakInterceptorInstance.requestOnRejected
    )
    APIV3.http?.interceptors.response.use((response) => {
      if (response.headers['x-trimoz-api-version']) {
        APIV3.apiVersion = response.headers['x-trimoz-api-version']
      }
      if (response.headers['x-trimoz-api-version-date']) {
        APIV3.apiVersionDate = response.headers['x-trimoz-api-version-date']
      }
      if (response.headers['x-trimoz-sec-whitelisted']) {
        APIV3.apiSecurityWhitelistingIp = response.headers['x-trimoz-sec-whitelisted']
      }
      return response
    })
  }
  // To do : remove after keycloak cleanup
  else {
    const jwtInterceptor = new JwtInterceptor(APIV3, auth(APIV3).getAccessToken, useJwtStore)
    APIV3.http?.interceptors.request.use(
      jwtInterceptor.requestOnFulfilled,
      jwtInterceptor.requestOnRejected
    )
    APIV3.http?.interceptors.response.use(
      jwtInterceptor.responseOnFulfilled,
      jwtInterceptor.responseOnRejected
    )
  }

  // eslint-disable-next-line
  return {
    auth: auth(APIV3),
    avails: avails(APIV3),
    geocoder: geocoder(APIV3),
    headOffices: headOffices(APIV3),
    settings: settings(APIV3),
    status: status(APIV3),
    favorites: favorites(APIV3),
    category: category(APIV3),
    serviceUnified: serviceUnified(APIV3),
    serviceUnifiedWithTags: serviceUnifiedWithTags(APIV3),
    serviceTemplates: serviceTemplates(APIV3),
    placeServices: placeServices(APIV3),
    appointment: appointment(APIV3),
    statistics: statistics(API_STATIC)
  }
}
