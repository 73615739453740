import { LS_TRIMOZ_LANG } from '@/enums'
import { useGlobalStore } from '@/stores/global'

export interface Slugs {
  fr: string
  en: string
}

export function useSlugHelper() {
  const checkServiceSlug = async (path: string, slugs: Slugs) => {
    return await checkSlug(path, slugs, '/services/')
  }

  const checkPageSlug = async (path: string, slugs: Slugs) => {
    return await checkSlug(
      path,
      {
        fr: `/${slugs.fr}`,
        en: `/${slugs.en}`
      },
      ''
    )
  }

  const checkSlug = async (path: string, slugs: Slugs, prefix: string) => {
    const isSlugFr = path === slugs.fr
    const isSlugEn = path === slugs.en

    if (isSlugFr || isSlugEn) {
      await isExternal(isSlugFr ? 'fr' : 'en')

      const localeSlug = useGlobalStore().locale === 'fr' ? slugs.fr : slugs.en
      if (path !== localeSlug) {
        return prefix + localeSlug
      }
    }
    return null
  }

  const isExternal = async (locale) => {
    const referrer = document.referrer
    const isExternal = !referrer || !referrer.includes(window.location.hostname)
    if (isExternal && !localStorage.getItem(LS_TRIMOZ_LANG)) {
      localStorage.setItem(LS_TRIMOZ_LANG, locale)
      await useGlobalStore().setLocale(locale)
      return true
    }
    return false
  }

  return {
    checkServiceSlug,
    checkPageSlug
  }
}
